

.plan-button {
        background: #fff;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        color: #111;
        cursor: pointer;
        font-size: 18px;
        text-align: center;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        margin-bottom: 20px;
      }

.button:active {
    box-shadow: 0 6px 12px rgba(0,0,0,0.19), 0 3px 4px rgba(0,0,0,0.23);
    transform: translateY(1px);
}

.plan-button.selected {
    background: #43240a;
    color: #fff;
}


.plan-header {
  font-weight: 700;
}

.plan-description {
    line-height: initial;
    padding: 10px;
}

content {
    height: 100vh
}

footer {
    position: sticky;
    height: 160px;
    background-color: #fff;
}

main {
    height: calc(100vh - 265px);
    overflow: hidden;
}

.plan-container { 
    overflow: auto;
    height: 100%;
    padding: 20px;
}

.plan-container p {
    font-size: 32px;
}


input[type=text]{
    width:100%;
    border:2px solid #aaa;
    border-radius:4px;
    margin:8px 0;
    outline:none;
    padding:8px;
    box-sizing:border-box;
    transition:.3s;
  }
  
  input[type=text]:focus{
    border-color:dodgerBlue;
    box-shadow:0 0 8px 0 dodgerBlue;
  }