html, body {
  height: 100%;
  background-color: rgb(255 253 244);
}

#root {
  padding: 0;
  margin: 0;
}

nav {
  position: relative
}

header {
  position: relative;
  display: block;
  height: 105px;
}

.main-pitch {
  padding: 50px;
  font-size: 42px;
}

@media only screen and (max-width: 500px) {
  .main-pitch {
    padding: 25px;
    font-size: 2.5em;
  }
}

footer { 
  background-color: #dcdcdc;
}

main {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.landing-page {
  height: 100%;
}

.image-container {
  width: 100%;
  margin-top: 20px;
}

.image-container img {
  height: 100%; 
  width: 100%;
  object-fit: contain;
}


