    .button {
    background: #43240a;
    border-radius: 40px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    text-align: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .button:active {
    box-shadow: 0 6px 12px rgba(0,0,0,0.19), 0 3px 4px rgba(0,0,0,0.23);
    transform: translateY(1px);
  }

  .button.disabled { 
    background-color:  grey
  }
  